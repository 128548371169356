const validation = require('./validation');
const paths = require('./paths');
const constant = require('./constant');
const actions = require('./actions');
const hash = require('./hash');
const tokens = require('./tokens');
const time = require('./time');
const functions = require('./functions');
const messages = require('./messages');
const reports = require('./reports');
const recaptcha = require('./recaptcha');

module.exports.paths = paths;
module.exports.validation = validation;
module.exports.constant = constant;
module.exports.actions = actions;
module.exports.hash = hash;
module.exports.tokens = tokens;
module.exports.time = time;
module.exports.functions = functions;
module.exports.messages = messages;
module.exports.reports = reports;
module.exports.recaptcha = recaptcha;