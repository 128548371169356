module.exports = {
    APP_BASE: '/app',
    APP_LOGIN: '/app/login',
    APP_LOGOUT: '/app/logout',
    APP_REGISTER: '/app/sign-up',
    APP_ACCOUNT_CONFIRM: '/app/confirm',
    APP_FORGOT_PASSWORD: '/app/forgot-password',
    APP_RESET_PASSWORD: '/app/reset-password',
    APP_WEB_SUBSCRIPTION: '/app/web-subscription',
    APP_NEW_JOB: '/app/new-job',
    APP_EDIT_JOB: '/app/jobs/edit/:id',
    APP_JOBS: '/app/jobs',
    APP_OFFERS: '/app/offers',
    APP_CONTRACTS: '/app/contracts',
    APP_INVITES: '/app/invites',
    APP_SCHEDULE: '/app/schedule',
    APP_PROFILE: '/app/profile',
    APP_SUBSCRIBE: '/app/subscribe',
    APP_INVOICES: '/app/invoices',
    APP_PAYMENT_METHOD: '/app/payment-method',
    APP_SUBSCRIPTIONS: '/app/subscriptions',
    APP_MESSAGES: '/app/messages',
    APP_REPORTS: '/app/reports',
    APP_NOTIFICATIONS: '/app/notifications',
    APP_SECURITY: '/app/security',
    APP_PAYMENT_OPTIONS: '/app/payment-options',
    APP_JOBBER_PROFILE: '/app/jobber/profile/:id',
    APP_INVITES_REQUEST: '/app/invites/job',
    APP_FAQ: '/faq',
    APP_USER_FAQ: '/app/faq',
    APP_USER_TERMS: '/app/terms-conditions',
    APP_PRICING: '/pricing',
    APP_TERMS: '/terms-conditions',
    APP_PRIVACY: '/privacy-policy',
    APP_WOKRING_HOURS: '/app/working-hours',
    APP_SUPPORT: '/app/support',
    APP_SUB_USERS: '/app/sub-users',
    APP_REDIRECT: '/redirect',

    ADMIN_BASE: '/admin',
    ADMIN_LOGIN: '/admin/login',
    ADMIN_LOGOUT: '/admin/logout',
    ADMIN_DASHBOARD: '/admin/dashboard',
    ADMIN_USERS: '/admin/users',
    ADMIN_JOBS: '/admin/jobs',
    ADMIN_SUBS: '/admin/payments',
    ADMIN_CHAT: '/admin/chat',
    ADMIN_EMAIL: '/admin/email',
    ADMIN_EDIT_USER: '/admin/edit/user/:id',
    ADMIN_EDIT_JOB: '/admin/edit/job/:id',

    FIREBASE_MESSAGING_SW: '/firebase-messaging-sw.js',
    PUSHER_SW: 'static/js/pusher.js'
};
